body {
    padding-top: 0px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    src: url('../fonts/Roboto-Regular.ttf') format("opentype");
    font-size: 14px !important              ;
}

/* Closed submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
    content: " \f0d7";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

/* Opened submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
    content: " \f0da";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

.logo-section {
    height: 35px;
    background: #0672CB 0% 0% no-repeat padding-box;
}
.logo-section img {
    height: 18px;
}
.logo-section .col-sm-3 {
    top: 8px;
    position: relative;
}
.logo-section .col-sm-1 {
    text-align: center;
    border-left: 2px solid #025293;
    height: 26px;
    top: 5px;
    position: relative;
}
.logo-section .col-sm-8 {
    top: 4px;
    position: relative;
}
.logo-section .col-sm-8 img {
    height: 24px;
    margin-left: -12%;
}
.form-horizontal .form-group.m-0.img {
    margin-bottom: 0px;
    margin-top: -15px;
    height: 100px;
    background: #F4F4F4 0% 0% no-repeat padding-box;
}
.img .img-section img {
    padding-left: 10px;
    width: 100px;
    height: 100px;
}
.form-group .text-section {
    padding-left: 28px;
    padding-top: 25px;
    text-align: left;
}
.form-group .text-section .large {
    font-size: large;
}
.form-group .text-section .small {
    font-size: small;
}
.left-section .text-left {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -1px;
    padding: 5px;
    color: #fff;
    background: #0672CB 0% 0% no-repeat padding-box;
}
.left-section .text-left .txt {
    padding-left:10px;  font-size:20px;
}
.left-section .bor-1 {
    border: 1px solid #A8A8A8;
}
.left-section .sml-txt {
    font-size: smaller; color: #6F6F6F; margin-top: 10px;
}
.video-menu #videopanel {
    margin-bottom: 15px;
    border-color: #5f9ea0;
    border-width: 2px;
    width: 100%;
    display: block;
    overflow: hidden;
}
.video-menu .videoplayer {
    border-color: #5f9ea0;
    border-width: 2px;
}
.c-section #pro_name {
    font-size:x-large; 
    margin-bottom:18px;
}
.c-section .tab {
    width:50%
}
.c-section .img-remove-off, .c-section .img-remove-on {
    width:30px; 
    margin-top:-6px;
}
.c-section .remove {
    margin-top: -25px;margin-left: 35px;
}
.c-section .remove-color {
    margin-top: -25px;margin-left: 35px;color:'#000'
}
.seqdata .color-3 {
    color: #333;
}
.c-section .tab.pull-right .install {
    width:115%;
}
.c-section .tab.pull-right .img-install-on, .c-section .tab.pull-right .img-install-off {
    width:30px;
    margin-top: -40px;
    margin-right: -45px;
}
#stepdata table {
    width: 103%;
}
#stepdata table .tleft {
    width: 50px;
    padding: 8px 15px;
    vertical-align: top;
}
#stepdata table .tcenter {
    padding:8px 15px; text-align:left; font-size:medium;
}
#stepdata table .tright {
    padding:8px 15px; text-align:left; font-size:12px;
}
#stepdata table .tstep {
    color: #a9a9a9;
    font-size: 12px;
}
#stepdata table .bor-grey {
    border: inset 1px #808080;
}
.menu-title {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    position: relative;
}
.menu-title:hover {
    /* background-color: gainsboro;
        border-left: 2px solid #368AFF;*/
    cursor: pointer;
}


.menu-title.active {
    color: #01aaff;
    /*font-weight: bold;*/
}

.menu-title.active::after {
    content:'';
    background-image: url(../images/chevron-down-24.png);
    width: 16px;
    height: 16px;
    font-weight: normal;
    display: block;
    text-align: right;
    margin-top: -20px;
    position: absolute;
    right: 0;
    background-size: 100%;
}

.menu-parent.active {
    /*border-bottom: inset 1px gray;*/
}

.submenu-title {
    font-size: 12px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.submenu-title:hover {
    /* background-color: gainsboro;
            border-left: 2px solid #368AFF;*/
    cursor: pointer;
    /* color: #01aaff; */
}

.submenu-title.active {
    color: #01aaff;
}

.submenu-text {
    height: 60px;
    padding-left: 10px;
    margin-bottom: 2px;
    background: #EDEDED 0% 0% no-repeat padding-box;
    padding-top: 13px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #41B6E6;
}

.submenu-text.active {
    color: white;
    background: #41B6E6 0% 0% no-repeat padding-box;
}

.submenu-text:hover {
    background-color: gainsboro;
    cursor: pointer;
}

.seqdata {
    width: 234px;
    min-height: 327px;
    max-height: 330px;
    overflow-x: hidden;
    overflow-y: auto;

}

#seqdata::-webkit-scrollbar {
    background: #fff;
    width: 4px;
}

#seqdata::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

#seqdata::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #808080;
}

.step-icon {
    width: 50px;

}

.step-icon1 {
    width: 25px;
    position: absolute;
    right: 60px;

}

img.step-icon2 {
    width: 25px;
    position: absolute;
    right: 30px;
}

.tab {
    color: darkgray;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.tab.active {
    color: #41B6E6;

}
.p-15 {
    padding: 0 15px;
}
.text-right {
    text-align: right;
}
.grid-design {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
@media (min-width: 1201px) {
    .container.body-content {
        max-width: 1170px;
        padding: 0;
    }
}
@media (max-width: 991px) {
    .seqdata {
        width: 100%;
        min-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .form-group.m-0 {
        margin-left: auto;
        margin-right: auto;
    }

    #videopanel {
        margin-top: 15px;
    }

    .video-menu,
    .video-menu .row {
        margin: 0 auto;
        padding: 0;
    }

    .p-0 {
        padding: 0;
    }
}

@media (max-width: 767px) {
    video#videoplayer {
        width: 100%;
        height: auto;
    }

    .logo-section {
        display: grid;
        grid-template-columns: 0% 40% 2% 58%;
    }

    .logo-section .col-sm-8 img {
        margin-left: 0 !important;
    }

    #stepdata table {
        width: 100% !important;
    }

    .video-menu .install {
        width: 100% !important;
    }

    .video-menu .install {
        width: 100% !important;
        padding-right: 45px;
    }

    .pull-right img {
        margin-right: 0 !important;
    }
}

@media (max-width: 480px) {
    .logo-section {
        display: grid;
        grid-template-columns: 0% 45% 2% 53%;
    }

    .logo-section img {
        max-width: 100%;
    }

    .form-group .img-section {
        width: 120px;
        float: left;
    }
}

.selectBoxPro {
    border: 1px solid #707070;
    margin-left: 28px;
    outline: none;
    width: 253px;
    height: 45px;
    padding: 10px;
}
.p6px{
    padding: 5px ;
}
@media (max-width: 990px) {
    .form-group .text-section {
       padding-left: 70px;
    }
    .selectBoxHeader{
        padding-right: 17px;
    }
}
@media (max-width: 965px) {
    .selectBoxHeader{
        padding-right: 17px;
        text-align: left;
    }
    .selectBoxPro{
        margin-left: 5px;
        width: auto;
        height: 30px;
        padding: 0px;
    }
}
.selectBoxHeader{
    padding-top: 29px;
    padding-right: 109px;
}
.fs-18{
    font-size: 18px;
}